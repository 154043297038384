import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import { Form, Modal, Space } from 'antd';
import { ProFormSelect, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import { handleErrors } from "../../utils/handleErrors";
import { updateReadById } from "../../services/update";
import moment from "moment";

export const NotificationViewNew = ({ notification, isShow, setIsShow, handleGetAlerts, user, isFirebase = false, payload = {} }) => {
  const handleRead = async (id) => {
    try {
      const result = await updateReadById(id, user.accessToken);
      if (result?.status === 204) {
        handleGetAlerts();
        setIsShow(false);
      }
    } catch (e) {
      handleErrors(e, user.logoutUser);
    }
  }
  console.log('notification', notification)

  return (
    <Modal
      visible={isShow}
      title={'Xem thông báo'}
      onCancel={() => setIsShow(false)}
      width={900}
      maskClosable={false}
      destroyOnClose
      onOk={() => handleRead(notification._id)}
      okText={'Đã xem'}
      cancelText={isFirebase ? "Đóng" : (notification.isRead ? 'Đóng' : 'Chưa xem')}
      okButtonProps={{
        hidden: isFirebase,
      }}
    >
      {
        notification?.subject === "Hồ sơ tham gia nghiên cứu đã được duyệt" &&
        <div>
          <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px", marginBottom: "10px" }}>
            <div>Trung tâm Đánh giá Tương đương sinh học - Viện Kiểm nghiệm thuốc TP HCM thông báo</div>
          </div>
          <div>
            <div>Đề tài Anh/chị đăng ký tham gia có mã đề tài: <b>{notification?.projectCode}</b> đã được phê duyệt.</div>
            <div>Anh/ Chị đọc kỹ thông tin nghiên cứu và đi khám sức khỏe đúng thời gian quy định. Trước và sau khi đi khám sức khoẻ, nếu anh/ chị có bất cứ câu hỏi nào, vui lòng liên Trung tâm thông qua số điện thoại / zalo 0928 284 999 để tư vấn</div>
            <div>Xin cảm ơn.</div>
          </div>
        </div>
      }
      {
        notification?.subject === "Hồ sơ tham gia nghiên cứu đã chính thức" &&
        <div>
          <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px", marginBottom: "10px" }}>
            <div>Trung tâm Đánh giá Tương đương sinh học - Viện Kiểm nghiệm thuốc TP HCM thông báo</div>
          </div>
          <div>
            <div>Kết quả tuyển chọn của Anh/ Chị đạt tiêu chuẩn, anh/chị đã được chọn tham gia chính thức đề tài <b>{notification?.projectCode}</b></div>
            <div>Vui lòng xem lịch tiến hành và tham gia đúng thời gian quy định. </div>
            <div>Trước khi tham gia nghiên cứu, nếu anh/chị có bất cứ câu hỏi nào, vui lòng liên lạc với Trung tâm qua số điện thoại / zalo 0928 284 999 để được tư vấn. </div>
            <div>Xin cảm ơn anh/chị đã dành thời gian tham gia nghiên cứu! </div>
            <div>Mọi thông tin cá nhân, thông tin về việc tham gia thử nghiệm cùng kết quả xét nghiệm sẽ được bảo mật trong hồ sơ nghiên cứu.</div>
          </div>
        </div>
      }
      {
        notification?.subject === "Hồ sơ tham gia nghiên cứu đã được tham gia dự bị" &&
        <div>
          <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px", marginBottom: "10px" }}>
            <div>Trung tâm Đánh giá Tương đương sinh học - Viện Kiểm nghiệm thuốc TP HCM thông báo</div>
          </div>
          <div>
            <div>Kết quả tuyển chọn của Anh/ Chị đạt tiêu chuẩn, anh/chị đã được chọn tham gia dự bị đề tài <b>{notification?.projectCode}</b></div>
            <div>Vui lòng xem lịch tiến hành và tham gia đúng thời gian quy định. </div>
            <div>Trước khi tham gia nghiên cứu, nếu anh/chị có bất cứ câu hỏi nào, vui lòng liên lạc với Trung tâm qua số điện thoại / zalo 0928 284 999 để được tư vấn. </div>
            <div>Xin cảm ơn anh/chị đã dành thời gian tham gia nghiên cứu! </div>
            <div>Mọi thông tin cá nhân, thông tin về việc tham gia thử nghiệm cùng kết quả xét nghiệm sẽ được bảo mật trong hồ sơ nghiên cứu.</div>
          </div>
        </div>
      }
      {
        (notification?.subject === "Hồ sơ tham gia nghiên cứu được yêu cầu khám sức khỏe lại" || notification?.subject === "Hồ sơ tham gia nghiên cứu đã bị loại") &&
        <div>
          <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px", marginBottom: "10px" }}>
            <div>Trung tâm Đánh giá Tương đương sinh học - Viện Kiểm nghiệm thuốc TP HCM thông báo</div>
          </div>
          <div>
            <div>Sau khi đánh giá kết quả khám sức khỏe Trung tâm xin thông báo Anh/chị không đủ điều kiện tham gia đề tài <b>{notification?.projectCode}</b></div>
            <div>Lý do: <b>{notification?.content}</b></div>
            <div>Anh/chị có bất cứ câu hỏi nào, vui lòng liên lạc với Trung tâm qua số điện thoại/ zalo 0928 284 999 để được tư vấn.  </div>
            <div>Xin cảm ơn anh/chị đã dành thời gian tham gia nghiên cứu! </div>
            <div>Mọi thông tin cá nhân, thông tin về việc tham gia thử nghiệm cùng kết quả xét nghiệm sẽ được bảo mật trong hồ sơ nghiên cứu.</div>
          </div>
        </div>
      }
      {
        (notification?.subject === "Nghiên cứu mới" || isFirebase) &&
        <div>
          <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px", marginBottom: "10px" }}>
            <div>Trung tâm Đánh giá Tương đương sinh học - Viện Kiểm nghiệm thuốc TP HCM thông báo</div>
            <div>“Tuyển người tình nguyện tham gia nghiên cứu đánh giá Tương đương sinh học của thuốc”.</div>
          </div>
          <div>
            <div>Thuốc nghiên cứu: <b>{notification?.drug}</b></div>
            <div>Thuốc Điều trị bệnh: <b>{Array.isArray(JSON.parse(notification?.appoint || "{}")) ? JSON.parse(notification?.appoint || "{}").map(i => i.value).join(", ") : notification?.appoint}</b></div>
            <div>Thời gian nghiên cứu:</div>
            <div style={{ marginBottom: "10px" }}>
              {notification?.period1 && <div> - Giai đoạn 1: <b>{moment(JSON.parse(notification?.period1 || "[]")?.[0]?.date).format("DD/MM/YYYY")}</b></div>}
              {notification?.period2 && <div> - Giai đoạn 1: <b>{moment(JSON.parse(notification?.period2 || "[]")?.[0]?.date).format("DD/MM/YYYY")}</b></div>}
              {notification?.period3 && <div> - Giai đoạn 1: <b>{moment(JSON.parse(notification?.period3 || "[]")?.[0]?.date).format("DD/MM/YYYY")}</b></div>}
              {notification?.period4 && <div> - Giai đoạn 1: <b>{moment(JSON.parse(notification?.period4 || "[]")?.[0]?.date).format("DD/MM/YYYY")}</b></div>}
            </div>
          </div>
          <div>
            <div>**Anh chị đăng ký tham gia hoặc giới thiệu người thân tham gia.</div>
            <div>Hạn đăng ký: từ ngày <b>{notification?.startDate && moment(notification?.startDate).format("DD/MM/YYYY")}</b> đến ngày <b>{notification?.dealineKSKDate && moment(notification?.dealineKSKDate).format("DD/MM/YYYY")}</b></div>
            <div>Tiêu chuẩn đăng ký: </div>
            <div style={{ marginBottom: "10px" }}>
              <div> - Người khỏe mạnh, tình nguyện tham gia, tuổi từ 18-50.</div>
              <div> - Chỉ số BMI từ 18-30 kg/m2. Cân nặng ít nhất là 44 kg đối với nữ, 47kg đối với nam.</div>
            </div>
            {[].map(item => <div></div>)}
          </div>
          <div>
            <div>Lưu ý: </div>
            <div> - NTN không nghiện rượu, không nghiện thuốc lá, không sử dụng các chất gây nghiện NTN có tiền sử dị ứng hoặc mắc các bệnh mãn tính, đang dùng thuốc hoặc đang bị ốm, bị hoa mắt chóng mặt khi đói, sợ máu.</div>
            <div> - Đang Không có biểu hiện sốt, ho, sổ mũi, mệt mỏi, đau họng, đau đầu, hắt hơi, ho dai dẳng, giọng khàn, ớn lạnh, đau xương khớp….. </div>
            <div> - ĐỐI VỚI NỮ: KHÔNG TUYỂN CÁC BẠN TRONG CHU KỲ KINH NGUYỆT TRONG CẢ NGÀY SƠ TUYỂN VÀ GIAI ĐOẠN UỐNG THUỐC! </div>
            <div> - Chi tiết điều kiện tuyển chọn, quyền lợi và nhiệm vụ của người tình nguyện….</div>
            <div> - Mọi thắc mắc anh/chị liên hệ trung tâm qua số zalo SĐT: 0928 284 999. </div>
          </div>
          <div>Xin cảm ơn! </div>
        </div>
      }
      {/* <Form initialValues={notification}>
        <Space>
          <ProFormSelect
            readonly
            name="projectCode"
            label="Mã nghiên cứu"
            width={180}
            placeholder={'Chọn nghiên cứu'}
          />
          <ProFormText
            readonly
            name="projectName"
            label="Tên nghiên cứu"
            width={'100%'}
          />
        </Space>
      </Form>
      <Form initialValues={notification} layout={"vertical"}>
        <ProFormTextArea
          readonly
          layout={'vertical'}
          name="content"
          label="Nội dung"
          width={'100%'}
          placeholder={'Nhập nội dung'}
        />
      </Form> */}
    </Modal>);
};


