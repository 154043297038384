import React, { useContext, useState } from 'react';
import 'antd/dist/antd.css';
import '../../index.scss'
import { Popover } from 'antd';
import { logout } from "../../context/authContext/AuthActions";
import { InfoIcon, LastIcon, MiddleIcon, ProfileIcon, UserIcon } from "../../icons";
import { AuthContext } from "../../context/authContext/AuthContext";
import PasswordUpdate from "../../forms/password-update";
import UpdateAccountForm from "../../forms/account-update";
import { handleLogout } from '../../services/update';

export const MenuUser = ({ user, refresh }) => {
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
  const [showAccountUpdate, setShowAccountUpdate] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const { dispatch } = useContext(AuthContext);
  return <Popover
    placement="bottomLeft"
    visible={visiblePopover}
    onVisibleChange={() => setVisiblePopover(!visiblePopover)}
    title={<ProfileIcon title={'Username'} label={user?.username} />} content={<div>
      <PasswordUpdate user={user} setIsShow={setShowPasswordUpdate} isShow={showPasswordUpdate} />
      <UpdateAccountForm refresh={refresh} user={user} setIsShow={setShowAccountUpdate} isShow={showAccountUpdate} />
      <MiddleIcon
        icon={'password.svg'}
        label={'Đổi mật khẩu'}
        title={'Đổi mật khẩu'}
        onClick={() => {
          setShowPasswordUpdate(true);
          setVisiblePopover(false);
        }}
      />
      <InfoIcon
        icon={'info.svg'}
        label={'Cập nhật thông tin'}
        title={'Cập nhật thông tin'}
        onClick={() => {
          setShowAccountUpdate(true);
          setVisiblePopover(false);
        }}
      />
      <LastIcon
        icon={'signout.svg'}
        label={'Đăng xuất'}
        title={'Đăng xuất'}
        onClick={async() => {
          await handleLogout()
          dispatch(logout())
        }}
      />
    </div>} trigger="click">
    <UserIcon title={'Thông tin người dùng'} />
  </Popover>;
}
