import React from 'react';
import 'antd/dist/antd.css';
import {Modal} from "antd";

export const infoModal = (onOk, title, okText, content) => {
  Modal.info({
    title: title || 'Thông báo',
    content: content || (
      <div>
        <p>{'Cảm ơn bạn đã đăng ký!'}</p>
        <p>{'Quản trị viên sẽ kiểm tra hồ sơ của bạn và gửi phản hồi qua email hoặc qua tài khoản của bạn!'}</p>
      </div>
    ),
    onOk() {
      onOk();
    },
    okText: okText || 'Chấp nhận',
  });
}
