import React, { useContext, useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Col, Divider, Form, Modal, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import * as apis from '../../services/update'
import { AuthContext } from "../../context/authContext/AuthContext";
import '../../index.scss'
import { loginAgain } from "../../context/authContext/AuthActions";
import { ProFormText } from '@ant-design/pro-form';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { requiredEmailRule } from "../../utils/rules";
import { recoverPassword } from "../../services/get";
import { handleErrors } from "../../utils/handleErrors";
import PasswordUpdate from '../../forms/password-update';
import { useEffect } from 'react';
import { getNewsList } from '../../services/news.service';
import { getAdvertisementList } from '../../services/advertisement.service';
import { getActionImageList } from '../../services/actionImage.service';
import { getLinkURL, sortDescending } from '../../utils';
import imageDefault from '../../images/background.jpg'

const Login = () => {
  let formRef = null;
  const history = useHistory();
  const { dispatch, error, isFetching } = useContext(AuthContext);
  const [showRecoverPassword, setShowRecoverPassword] = useState(false);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
  const [data, setData] = useState({})
  const [dataNews, setDataNews] = useState([]);
  const [dataAds, setDataAds] = useState([]);
  const [dataActionImage, setDataActionImage] = useState([]);
  const [hot, setHot] = useState({});

  useEffect(async () => {
    const [d1, d2, d3] = await Promise.all([
      getNewsList(),
      getAdvertisementList(),
      getActionImageList(),
    ])
    const hot = (d1.data || []).find(i => i.isHot == true)
    setHot(hot || {})
    setDataNews(d1.data.filter(i => i.isHot !== true).sort(sortDescending).slice(0, 4))
    setDataAds(d2.data.sort(sortDescending).slice(0, 5))
    setDataActionImage(d3.data.sort(sortDescending).slice(0, 4))
  }, [])
  console.log('hot', hot)

  const onFinish = async (user) => {
    const res = await apis.login(user, dispatch)
    if (res?.data?.statusCode === 202) {
      setShowPasswordUpdate(true)
      setData({
        _id: res?.data?.userId,
        accessToken: res?.data?.accessToken,
      })
    }
  };
  const handleRecoverPassword = async (values) => {
    try {
      const result = await recoverPassword(values.email);
      if (result?.status === 204) {
        setShowRecoverPassword(false);
        Modal.success({
          title: 'Khôi phục mật khẩu thành công!',
          content: (<div>
            <p>{'Vui lòng kiểm tra địa chỉ email đã đăng ký!'}</p>
          </div>),
          onOk() { },
          okText: 'Đóng',
        });
      }
    } catch (e) {
      handleErrors(e);
    }
  }

  return (<div className={'container-login-new'}>
    <PasswordUpdate user={data} setIsShow={setShowPasswordUpdate} isShow={showPasswordUpdate} isFirst={true} />
    <div className='bg_header'></div>
    <Row>
      <Col span={16} style={{ position: "relative", height: "397px", cursor: "pointer" }}>
        <img src={hot?.image || imageDefault} style={{ width: "100%", height: "390px" }} />
        <div style={{ position: "absolute", bottom: 0, left: 0, right: 0, height: "45px", fontSize: "16px", opacity: 0.7, background: "black", color: "white", fontWeight: "bold", textAlign: "center", paddingTop: "10px" }} >
          {hot.title}
        </div>
      </Col>
      <Col span={8}>
        <div className={'login'}>
          <Modal
            visible={showRecoverPassword}
            title={'Khôi phục mật khẩu'}
            onCancel={() => setShowRecoverPassword(false)}
            width={400}
            maskClosable={false}
            destroyOnClose
            onOk={() => formRef.submit()}
            okText={'Khôi phục'}
            cancelText={'Hủy'}
          >
            <Form
              layout={"vertical"}
              onFinish={(values) => handleRecoverPassword(values)}
            >
              {(values, form) => {
                formRef = form;
                return <ProFormText
                  rules={requiredEmailRule}
                  name="email"
                  label="Địa chỉ Email"
                  width={'100%'}
                  placeholder={'Nhập địa chỉ Email'}
                />;
              }}
            </Form>
          </Modal>
          <Divider orientation={'center'}>{'Đăng nhập tài khoản'}</Divider>
          <Form layout={'vertical'} onFinish={(values) => !error && onFinish({ ...values, tokenFCM: localStorage.getItem("tokenFCM") || "eZyggdBz9lpxIaT3lUdjl8:APA91bEbJrjuS3RBzgQNSXqYZJxrYQrHrooH_EwyV7tZjWRwoaKPkHeBd-O7nc1siKYD0BHpuj9K_CaFDF0hl6FCRSbPGK4OfU9pRqVoNQ57CG6WQMThsSgVxD3xXa_d4G6LVIw5I-4P" })}>
            <Row>
              {error && <p style={{ color: 'red' }}>{'Tài khoản hoặc mật khẩu không đúng!'}</p>}
            </Row>
            <Row>
              <Col span={24}>
                <ProFormText
                  name="username"
                  label={'Tài khoản'}
                  fieldProps={{
                    prefix: <UserOutlined className={'prefixIcon'} />,
                  }}
                  placeholder={'Tài khoản'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập tài khoản!',
                    },
                  ]}
                  onChange={() => dispatch(loginAgain())}
                />
                <ProFormText.Password
                  name="password"
                  label={'Mật khẩu'}
                  fieldProps={{
                    prefix: <LockOutlined className={'prefixIcon'} />,
                  }}
                  placeholder={'Mật khẩu'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mật khẩu!',
                    },
                  ]}
                  onChange={() => dispatch(loginAgain())}
                />
                <Form.Item>
                  <Button className={'button'} loading={isFetching} type="primary" htmlType="submit">{'Đăng nhập'}</Button>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item>
                  <a onClick={() => history.push('/register-account')}>{'Đăng ký tài khoản'}</a>
                </Form.Item>
              </Col>
              <Col span={12}  style={{ textAlign: "right"}} >
                <Form.Item>
                  <a onClick={() => setShowRecoverPassword(true)}>{'Quên mật khẩu?'}</a>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
    <Row>
      <Col span={16}>
        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px", margin: "10px 20px 0px 20px", borderBottom: "1px black solid", fontWeight: "bold" }}>
          <div>Tin tức hoạt động</div>
          <div onClick={() => history.push("/news?type=news")} style={{ cursor: "pointer", color: "#1890ff" }}>Xem thêm</div>
        </div>
        <Row>
          {
            dataNews.map(item => <Col span={12} style={{ padding: "20px 0 10px 20px", cursor: "pointer" }} onClick={() => history.push(`/news/${item._id}?type=${"news"}`)} >
              <img src={item?.image ? getLinkURL(item?.image, "news") : imageDefault} style={{ width: "100%", height: "200px", objectFit: "cover" }} />
              <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>{item?.title}</div>
            </Col>)
          }
        </Row>
        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px", margin: "10px 20px 0px 20px", borderBottom: "1px black solid", fontWeight: "bold" }}>
          <div>Hình ảnh hoạt động</div>
          <div onClick={() => history.push("/action-images")} style={{ cursor: "pointer", color: "#1890ff" }}>Xem thêm</div>
        </div>
        <Row>
          {
            dataActionImage.map(item => <Col onClick={() => { if (item?.link) window.open(item?.link, "_blank") }} span={6} style={{ padding: "20px 0 10px 20px" }}>
              {item?.type === "image" ?
                <img src={item?.image ? getLinkURL(item?.image, "news") : imageDefault} style={{ width: "100%", height: "140px", objectFit: "cover", cursor: "pointer" }} /> :
                <div style={{ width: "100%", height: "140px" }} className="iframe-action-image" dangerouslySetInnerHTML={{ __html: item?.urlVideo }} >
                </div>
              }
            </Col>)
          }
        </Row>
      </Col>
      <Col span={8}>
        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px", margin: "10px 20px 0px 20px", borderBottom: "1px black solid", fontWeight: "bold" }}>
          <div>Quảng cáo</div>
          <div onClick={() => history.push("/news?type=ads")} style={{ cursor: "pointer", color: "#1890ff" }}>Xem thêm</div>
        </div>
        <Row>
          {
            dataAds.map(item => <Col span={24} style={{ display: "flex", padding: "20px", paddingBottom: 0, cursor: "pointer" }} onClick={() => history.push(`/news/${item._id}?type=${"ads"}`)} >
              <img src={item?.image ? getLinkURL(item?.image, "news") : imageDefault} style={{ width: "40%", height: "120px", objectFit: "cover" }} />
              <div style={{ width: "60%", fontWeight: "bold", fontSize: "16px", display: "flex", alignItems: "center", padding: "5px" }} >{item?.title}</div>
            </Col>)
          }
        </Row>
      </Col>
    </Row>
  </div>);

  return (<div className={'container-login'}>
    <PasswordUpdate user={data} setIsShow={setShowPasswordUpdate} isShow={showPasswordUpdate} isFirst={true} />
    <div className={'login'}>
      <Modal
        visible={showRecoverPassword}
        title={'Khôi phục mật khẩu'}
        onCancel={() => setShowRecoverPassword(false)}
        width={400}
        maskClosable={false}
        destroyOnClose
        onOk={() => formRef.submit()}
        okText={'Khôi phục'}
        cancelText={'Hủy'}
      >
        <Form
          layout={"vertical"}
          onFinish={(values) => handleRecoverPassword(values)}
        >
          {(values, form) => {
            formRef = form;
            return <ProFormText
              rules={requiredEmailRule}
              name="email"
              label="Địa chỉ Email"
              width={'100%'}
              placeholder={'Nhập địa chỉ Email'}
            />;
          }}
        </Form>
      </Modal>
      <Divider orientation={'center'}>{'Đăng nhập tài khoản'}</Divider>
      <Form layout={'vertical'} onFinish={(values) => !error && onFinish(values)}>
        <Row>
          {error && <p style={{ color: 'red' }}>{'Tài khoản hoặc mật khẩu không đúng!'}</p>}
        </Row>
        <Row>
          <Col span={24}>
            <ProFormText
              name="username"
              label={'Tài khoản'}
              fieldProps={{
                prefix: <UserOutlined className={'prefixIcon'} />,
              }}
              placeholder={'Tài khoản'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tài khoản!',
                },
              ]}
              onChange={() => dispatch(loginAgain())}
            />
            <ProFormText.Password
              name="password"
              label={'Mật khẩu'}
              fieldProps={{
                prefix: <LockOutlined className={'prefixIcon'} />,
              }}
              placeholder={'Mật khẩu'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu!',
                },
              ]}
              onChange={() => dispatch(loginAgain())}
            />
            <Form.Item>
              <Button className={'button'} loading={isFetching} type="primary" htmlType="submit">{'Đăng nhập'}</Button>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {/* <Col span={12}>
            <Form.Item>
              <a onClick={() => setShowRecoverPassword(true)}>{'Quên mật khẩu?'}</a>
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item>
              <a onClick={() => history.push('/register-account')}>{'Đăng ký tài khoản'}</a>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  </div>);
};

export default Login;