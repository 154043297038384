import { TOKEN_NAME } from "../constants";
import axiosService from "./axiosService";

export function getAdvertisementList(dataReq) {
  return axiosService.get(`/api/get/advertisement-client`, dataReq)
}

export function getDetailAdvertisement(id) {
  return axiosService.get(`/api/get/advertisement-detail/${id}`)
}

export function createAdvertisement(data) {
  const token = localStorage.getItem(TOKEN_NAME);
  let formData = new FormData();
  console.log('data', data)
  if (data.image) formData.append("image", data.image);
  if (data.content) formData.append("content", data.content);
  if (data.title) formData.append("title", data.title);
  const result = axiosService.post(`/api/create/advertisement`, formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      }
    }
  );
  return result;
  // return axiosService.post(`/api/create/advertisement`, params)
}

export function editAdvertisement(id, params) {
  return axiosService.put(`/api/update/update-advertisement/${id}`, params)
}

export function deleteAdvertisement(id) {
  return axiosService.delete(`/api/delete/advertisement/${id}`)
}

