const cloudStorage = 'https://storage.googleapis.com/idqc-hcm/';
const youtubeEmbed = 'http://www.youtube.com/embed/';

export const getLinkURL = (fileName, folder) => `${cloudStorage}${folder}/${fileName}`;

export const getVideoURL = (fileName) => `${youtubeEmbed}${fileName}`;

export const getProjectCode = (value) => {
  const code = value?.substring(0,2);
  const year = new Date().getFullYear().toString();
  return code + year.substring(3,4);
};

export const getProjectCodeNew = (value = "") => {
  console.log('value', value)
  if (value.slice(-2) == "FE") {
    return value.slice(4, -2).slice(0, 2) + (value.slice(1, 2) || "")
  }
  else {
    return value.slice(4).slice(0, 2) + (value.slice(1, 2) || "")
  }
};

export const sortDescending = (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
