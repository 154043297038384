import React, {useState} from 'react';
import 'antd/dist/antd.css';
import '../../index.scss';
import {LoadingOutlined} from '@ant-design/icons';
import {getLinkURL} from '../../utils';
import {Form} from 'antd';

const ImageUpdate = ({name, image, height, width, callback, className, label, required}) => {
  const [loading, setLoading] = useState(false);
  const [reviewImage, setReviewImage] = useState(null);

  const previewImage = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setReviewImage(reader.result);
      setLoading(false);
    }
  };

  const onChange = function (event) {
    setLoading(true);
    const file = event.target.files[0];
    callback(file);
    previewImage(file);
  };

  const review = <div style={{ cursor: 'pointer'}} title={'Đổi hình ảnh'}>
    <img src={reviewImage} height={height} width={width}/>
    <input accept="image/*" style={{display: 'none'}} id={name} type="file" name={name} onChange={onChange}/>
  </div>

  const empty = <div className={`components ${className}`} style={{ cursor: 'pointer'}} title={'Đổi hình ảnh'}>
    <input accept="image/*" style={{display: 'none'}} id={name} type="file" name={name} onChange={onChange}/>
    {loading ? <LoadingOutlined/> : <img alt="Icon" height={64} width={64} src={require('../../icons/camera.svg')?.default}/>}
  </div>

  const exist = <div style={{ cursor: 'pointer'}} title={'Đổi hình ảnh'}>
    <img src={getLinkURL(image, 'profiles')} height={height} width={width}/>
    <input accept="image/*" style={{display: 'none'}} id={name} type="file" name={name} onChange={onChange}/>
  </div>

  return <Form.Item name={name} label={label} required={required} rules={required && [
    {
      validator: (rule, value, callback) => {
        if (!value) {
          callback('Vui lòng chọn ảnh!');
        } else {
          callback();
        }
      }
    }
  ]}>
    <label className={`components label`} htmlFor={name}>{reviewImage ? review : (image ? exist : empty)}</label>
  </Form.Item>;
}

export default ImageUpdate;
