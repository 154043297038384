import React, { useContext, useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Col, Divider, Form, Modal, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import * as apis from '../../services/update'
import { AuthContext } from "../../context/authContext/AuthContext";
import '../../index.scss'
import { loginAgain } from "../../context/authContext/AuthActions";
import { ProFormText } from '@ant-design/pro-form';
import { LockOutlined, UserOutlined, HomeOutlined } from '@ant-design/icons';
import { requiredEmailRule } from "../../utils/rules";
import { recoverPassword } from "../../services/get";
import { handleErrors } from "../../utils/handleErrors";
import PasswordUpdate from '../../forms/password-update';
import { useEffect } from 'react';
import { getDetailNews, getNewsList } from '../../services/news.service';
import { getAdvertisementList, getDetailAdvertisement } from '../../services/advertisement.service';
import { getActionImageList } from '../../services/actionImage.service';
import { getLinkURL, sortDescending } from '../../utils';
import imageDefault from '../../images/background.jpg'

const NewDetail = () => {
  let formRef = null;
  const history = useHistory();
  const { dispatch, error, isFetching } = useContext(AuthContext);
  const [showRecoverPassword, setShowRecoverPassword] = useState(false);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
  const [data, setData] = useState({})
  const [dataNews, setDataNews] = useState([]);
  const [dataAds, setDataAds] = useState([]);
  const [dataActionImage, setDataActionImage] = useState([]);
  const [hot, setHot] = useState({});
  const query = useParams()
  const params = new URLSearchParams(window.location.search)
  const type = params.get("type") || "news"

  useEffect(async () => {
    console.log('query.id', query.id)
    if (!query.id) return
    const { data } = type === "news" ? await getDetailNews(query?.id) : await getDetailAdvertisement(query.id);
    setDataNews(data)
  }, [])
  console.log('hot', hot)

  const onFinish = async (user) => {
    const res = await apis.login(user, dispatch)
    if (res?.data?.statusCode === 202) {
      setShowPasswordUpdate(true)
      setData({
        _id: res?.data?.userId,
        accessToken: res?.data?.accessToken,
      })
    }
  };
  const handleRecoverPassword = async (values) => {
    try {
      const result = await recoverPassword(values.email);
      if (result?.status === 204) {
        setShowRecoverPassword(false);
        Modal.success({
          title: 'Khôi phục mật khẩu thành công!',
          content: (<div>
            <p>{'Vui lòng kiểm tra địa chỉ email đã đăng ký!'}</p>
          </div>),
          onOk() { },
          okText: 'Đóng',
        });
      }
    } catch (e) {
      handleErrors(e);
    }
  }

  return (<div className={'container-login-new'}>
    <div className='bg_header'></div>
    <Row style={{ margin: "20px", fontSize: "16px", paddingBottom: "10px", borderBottom: "1px black solid" }}>
      <HomeOutlined className='beadcrumb' onClick={() => history.push("/")} style={{ color: "#109CF1", fontSize: "20px", marginRight: "7px" }} /> / <span style={{ marginLeft: "7px", marginRight: "7px" }} className='beadcrumb' onClick={() => history.push("/news?type=" + type)}>{type === "news" ? "Tin tức hoạt động" : "Quảng cáo"}</span> / {dataNews?.title}
    </Row>
    <div style={{ margin: "20px", }}>
      <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>{dataNews?.title}</div>
      <div style={{ color: "#BDBDBD", marginTop: "10px", marginBottom: "10px" }}>Ngày đăng {new Date(dataNews?.createdAt).getDate() + "/" + (new Date(dataNews?.createdAt).getMonth() + 1) + "/" + new Date(dataNews?.createdAt).getFullYear()}</div>
      <div style={{}} dangerouslySetInnerHTML={{ __html: dataNews?.content }}></div>
    </div>

  </div>);

};

export default NewDetail;