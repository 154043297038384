import { TOKEN_NAME } from "../constants";
import axiosService from "./axiosService";

export function getActionImageList(dataReq) {
  return axiosService.get(`/api/get/action-image-client`, dataReq)
}

export function getDetailActionImage(id) {
  return axiosService.get(`/api/get/action-image-detail/${id}`)
}

export function createActionImage(data) {
  const token = localStorage.getItem(TOKEN_NAME);
  let formData = new FormData();
  console.log('data', data)
  if (data.image) formData.append("image", data.image);
  if (data.type) formData.append("type", data.type);
  if (data.link) formData.append("link", data.link);
  if (data.urlVideo) formData.append("urlVideo", data.urlVideo);
  const result = axiosService.post(`/api/create/action-image`, formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      }
    }
  );
  return result;
  // return axiosService.post(`/api/create/action-image`, params)
}

export function editActionImage(id, params) {
  return axiosService.put(`/api/update/update-action-image/${id}`, params)
}

export function deleteActionImage(id) {
  return axiosService.delete(`/api/delete/action-image/${id}`)
}

