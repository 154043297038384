import React, { useState } from 'react';
import 'antd/dist/antd.css';
import '../../index.scss'
import * as apis from '../../services/update';
import { infoModal } from "../../components/modals";
import { useHistory } from 'react-router-dom';
import { Button, Col, Divider, Form, message, message as Message, Row, Space, Spin, Upload } from 'antd';
import { ProFormDatePicker, ProFormRadio, ProFormText } from '@ant-design/pro-form';
import ImageUpdate from '../../components/images-update';
import { forOwn } from 'lodash';
import { numberRule, requiredEmailRule, requiredNumberRule, requiredTextRule } from "../../utils/rules";
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';

const RegisterAccountPage = () => {
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState();
  const [leftSideId, setLeftSideId] = useState();
  const [rightSideId, setRightSideId] = useState();
  const [fileList, setFileList] = useState([]);
  const [zaloQRCode, setZaloQRCode] = useState();
  const history = useHistory();
  const onOk = () => history.push("/")
  const onSubmit = async (values) => {
    try {
      // if (fileList?.[0]) values.zaloQRCode = fileList?.[0]?.originFileObj
      console.log('values', values.zaloQRCode)
      if (values.zaloQRCode && values.zaloQRCode.size > 1024 * 1024) {
        message.error("Dung lượng tối đa của QR Zalo là 1Mb", 10)
        return
      }
      setLoading(true);
      const formData = new FormData();
      forOwn(values, (value, key) => value && formData.append(key, value));
      const result = await apis.registerAccount(formData);
      if (result?.status === 204) {
        infoModal(onOk);
      }
      else {
        Message.error(result?.data?.message, 2);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e?.response?.status === 422) {
        Message.error(e?.response?.data, 2);
      } else {
        Message.error('Đã có lỗi xảy ra khi đăng ký tài khoản!', 2);
      }
    }
  };

  const updateDefaultProps = {
    listType: "picture",
    name: 'file',
    multiple: false,
    onChange({ _, fileList }) {
      setFileList(fileList);
    },
    onRemove: () => {
      setFileList([]);
    },
    fileList: fileList,
    uploading: false,
    beforeUpload: () => {
      return false;
    },
  }

  console.log('fileList?.[0]?.originFileObj', fileList?.[0]?.originFileObj)

  return <>
    <Divider orientation={'center'}>{'Đăng ký tài khoản'}</Divider>
    <div className={'register-page'}><Spin spinning={loading} tip={'Đang tạo tài khoản...'}>
      <div style={{ width: 1024, overflow: 'auto' }}>
        <Form
          name="basic"
          autoComplete="off"
          layout={"vertical"}
          footer={null}
          onFinish={(values) => onSubmit({ ...values, avatar, leftSideId, rightSideId, zaloQRCode })}
        >
          {({ height, weight }) => {
            const bmi = weight && height ? (weight / ((height * height) / 10000)).toFixed(2) : 0;
            return <>
              <Divider orientation={'left'}>{'Thông tin ảnh'}</Divider>
              <Row>
                <Col span={4} offset={1}>
                  <ImageUpdate
                    required={true}
                    name={'avatar'}
                    height={148}
                    width={148}
                    callback={setAvatar}
                    className={'upload-image'}
                    label={'Thêm ảnh cá nhân'}
                  />
                </Col>
                <Col span={6} offset={1}>
                  <ImageUpdate
                    required={true}
                    name={'leftSideId'}
                    height={148}
                    width={265}
                    callback={setLeftSideId}
                    className={'upload-image-id'}
                    label={'Mặt A giấy chứng minh thư'}
                  />
                </Col>
                <Col span={6} offset={1}>
                  <ImageUpdate
                    required={true}
                    name={'rightSideId'}
                    height={148}
                    width={265}
                    callback={setRightSideId}
                    className={'upload-image-id'}
                    label={'Mặt B giấy chứng minh thư'}
                  />
                </Col>
              </Row>
              <Divider orientation={'left'}>{'Thông tin chung'}</Divider>
              <Row>
                <Col span={11}>
                  <ProFormText
                    name={'name'}
                    label={'Họ tên'}
                    placeholder={'Nhập họ tên đầy đủ'}
                    rules={requiredTextRule}
                  />
                  <Space>
                    <ProFormRadio.Group
                      name="gender"
                      label={'Giới tính'}
                      options={[
                        {
                          label: 'Nam',
                          value: 'male'
                        },
                        {
                          label: 'Nữ',
                          value: 'female'
                        }
                      ]}
                      rules={requiredTextRule}
                    />
                    <ProFormDatePicker
                      name={'birthDate'}
                      label={'Ngày sinh'}
                      placeholder={'Ngày sinh'}
                      fieldProps={{
                        format: 'DD/MM/YYYY'
                      }}
                      rules={requiredTextRule}
                    />
                  </Space>
                  <ProFormText
                    name={'numberId'}
                    label={'Số CMND/CCCD'}
                    placeholder={'Nhập số CMND/CCCD'}
                    rules={requiredNumberRule}
                  />
                  <ProFormText
                    name={'address'}
                    label={'Địa chỉ liên hệ'}
                    placeholder={'Địa chỉ liên hệ'}
                    rules={requiredTextRule}
                  />
                  <ProFormText
                    name={'zaloInfo'}
                    label={'Thông tin zalo'}
                    placeholder={'Thông tin zalo'}
                    // rules={requiredTextRule}
                    rules={[
                      {
                        max: 10,
                        message: 'Tối đa 10 ký tự',
                      },
                      {
                        validator: (_, value) => (!value || (value || "").startsWith('0')) ? Promise.resolve() : Promise.reject(new Error("Số điện thoại phải bắt đầu bằng số 0"))
                      },
                    ]}
                  />
                  <div>hoặc</div>
                  <ImageUpdate
                    // required
                    name={'zaloQRCode'}
                    height={100}
                    width={100}
                    callback={setZaloQRCode}
                    className={'upload-image'}
                  />
                  {/* <Upload {...updateDefaultProps}>
                    <Button type="dashed" disabled={fileList.length > 0} className="upload-document-btn" icon={<UploadOutlined />}>{"Tải mã QR Zalo"}</Button>
                  </Upload> */}
                  <ProFormText
                    name={'birthPlace'}
                    label={'Nơi sinh'}
                    placeholder={'Nơi sinh'}
                  />
                </Col>
                <Col span={12} offset={1}>
                  <ProFormText
                    name={'cellPhone'}
                    label={'Điện thoại di động'}
                    placeholder={'Điện thoại di động'}
                    rules={requiredNumberRule}
                  />
                  <ProFormText
                    name={'email'}
                    label={'Địa chỉ Email'}
                    placeholder={'Nhập chính xác email'}
                    rules={requiredEmailRule}
                  />
                  <Row>
                    <Space>
                      <ProFormText
                        name={'height'}
                        label={'Chiều cao'}
                        placeholder={'vui lòng nhập'}
                        rules={requiredNumberRule}
                        fieldProps={{
                          suffix: ' cm'
                        }}
                      />
                      <ProFormText
                        name={'weight'}
                        label={'Cân nặng'}
                        placeholder={'vui lòng nhập'}
                        rules={requiredNumberRule}
                        fieldProps={{
                          suffix: ' kg'
                        }}
                      />
                      <ProFormText
                        disabled
                        value={bmi}
                        name={'bmi'}
                        label={'BMI'}
                        placeholder={'Chỉ số BMI'}
                        fieldProps={{
                          suffix: ' kg/m2'
                        }}
                      />
                    </Space>
                  </Row>
                  <ProFormText
                    name={'profession'}
                    label={'Nghề nghiệp'}
                    placeholder={'Nghề nghiệp'}
                  />
                  <ProFormText
                    name={'fbInfo'}
                    label={'Thông tin facebook'}
                    placeholder={'Thông tin facebook'}
                    rules={[
                      {
                        max: 256,
                        message: 'Tối đa 256 ký tự',
                      },
                    ]}
                  />
                  <Space>
                    <ProFormText
                      name={'nameVaccine'}
                      label={'Loại vacxin'}
                      placeholder={'Loại vacxin'}
                    />
                    <ProFormText
                      name={'injectNumber'}
                      label={'Mũi'}
                      placeholder={'Mũi'}
                      rules={numberRule}
                    />
                    <ProFormDatePicker
                      name={'injectDate'}
                      label={'Ngày tiêm'}
                      placeholder={'Ngày tiêm'}
                      fieldProps={{
                        format: 'DD/MM/YYYY'
                      }}
                    />
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: 'center' }} span={24}>
                  <Space>
                    <Button type="primary" loading={loading} danger
                      onClick={() => history.push('/login')}>{'Hủy'}</Button>
                    <Button type="primary" loading={loading} htmlType="submit">{'Gửi'}</Button>
                  </Space>
                </Col>
              </Row>
            </>
          }}
        </Form>
      </div>
    </Spin>
    </div>
  </>;
};

export default RegisterAccountPage;


