import axiosService from "./axiosService";
import { TOKEN_NAME } from "../constants";

export function getNewsList(dataReq) {
  return axiosService.get(`/api/get/news-client`, dataReq)
}

export function getDetailNews(id) {
  return axiosService.get(`/api/get/news-detail/${id}`)
}

export function createNews(data) {
  const token = localStorage.getItem(TOKEN_NAME);
  let formData = new FormData();
  console.log('data', data)
  if (data.image) formData.append("image", data.image);
  if (data.content) formData.append("content", data.content);
  if (data.title) formData.append("title", data.title);
  const result = axiosService.post(`/api/create/news`, formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      }
    }
  );
  return result;
  // return axiosService.post(`/api/create/news`, params)
}

export function editNews(id, params) {
  return axiosService.put(`/api/update/update-news/${id}`, params)
}

export function deleteNews(id) {
  return axiosService.delete(`/api/delete/news/${id}`)
}

