export const API_STATUS_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    UPDATED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    UNAUTHORIZED: 401,
    ERR_ACCESS_DENIED: 403,
    METHOD_NOT_ALLOW: 405,
    SERVICE_UNAVAIBLE: 503,
};

export const API_METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};
