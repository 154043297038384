import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Col, Form, message as Message, Modal, Row, Space, Spin } from 'antd';
import { ProFormDatePicker, ProFormRadio, ProFormText } from '@ant-design/pro-form';
import QRCode from 'qrcode.react';
import ImageUpdate from '../../components/images-update';
import { isEmpty } from 'lodash';
import { updateUser } from "../../services/update";
import { handleErrors } from "../../utils/handleErrors";
import { getProfileByUserId } from "../../services/get";

const UpdateAccountForm = ({ user, isShow, setIsShow, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState();
  const [leftSideId, setLeftSideId] = useState();
  const [rightSideId, setRightSideId] = useState();
  const [zaloQRCode, setZaloQRCode] = useState();
  const [profile, setProfile] = useState({});

  const getProfile = async function (userId) {
    try {
      const result = await getProfileByUserId(userId);
      return result;
    } catch (e) {
      handleErrors(e, user.logoutUser);
    }
  }

  useEffect(async () => {
    if (isShow) return
    const result = await getProfile(user._id);
    setProfile(result);
  }, [isShow]);

  const handleUpdate = async function (values) {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("lastName", values.lastName);
      formData.append("gender", values.gender);
      formData.append("birthDate", values.birthDate);
      formData.append("numberId", values.numberId);
      formData.append("cellPhone", values.cellPhone);
      formData.append("email", values.email);
      formData.append("height", values.height);
      formData.append("weight", values.weight);
      if (values.address) formData.append("address", values.address);
      if (values.birthPlace) formData.append("birthPlace", values.birthPlace);
      if (values.profession) formData.append("profession", values.profession);
      if (values.nameVaccine) formData.append("nameVaccine", values.nameVaccine);
      if (values.injectNumber) formData.append("injectNumber", values.injectNumber);
      if (values.injectDate) formData.append("injectDate", values.injectDate);
      if (values.avatar) formData.append("avatar", values.avatar);
      if (values.leftSideId) formData.append("leftSideId", values.leftSideId);
      if (values.rightSideId) formData.append("rightSideId", values.rightSideId);
      if (values.zaloQRCode) formData.append("zaloQRCode", values.zaloQRCode);
      if (values.zaloInfo) formData.append("zaloInfo", values.zaloInfo);
      if (values.fbInfo) formData.append("fbInfo", values.fbInfo);
      const result = await updateUser(user._id, formData, user.accessToken);
      if (result?.status === 204) {
        setLoading(false);
        setIsShow(false);
        refresh();
        Message.success('Cập nhật tài khoản thành công!', 10);
        window.location.reload();
      }
    } catch (e) {
      handleErrors(e, user.logoutUser, setLoading, 'Cập nhật tài khoản không thành công!');
    }
  }

  const onFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (<Modal title="Cập nhật thông tin" centered destroyOnClose closable={false} visible={isShow} width={1075} footer={null}>
    <Spin spinning={loading} tip={'Đang đổi...'}>
      <div style={{ width: 1024 }}>
        {!isEmpty(profile) && <Form
          name="basic"
          autoComplete="off"
          initialValues={profile}
          onFinish={(values) => handleUpdate({ ...values, avatar, leftSideId, rightSideId, zaloQRCode })}
          onFinishFailed={onFailed}
          footer={null}
        >
          {({ height, weight }) => {
            const bmi = weight && height ? (weight / ((height * height) / 10000)).toFixed(2) : 0;
            return <>
              <div style={{ marginBottom: 24 }}>
                <Row>
                  {profile?.codeId && <Col span={4}><label>{'Mã QR'}</label>
                    <div style={{
                      border: '1px solid #e2e2e2',
                      width: 148,
                      height: 148,
                    }}>
                      <QRCode
                        id='qrCodeId'
                        value={profile?.codeId}
                        size={146}
                        level={'H'}
                        includeMargin={true}
                      /></div>
                  </Col>}
                  <Col span={4} offset={1}>
                    <ImageUpdate
                      required
                      name={'avatar'}
                      image={profile?.avatar}
                      height={148}
                      width={148}
                      callback={setAvatar}
                      className={'upload-image'}
                      label={'Đổi ảnh cá nhân'}
                    />
                  </Col>
                  <Col span={6} offset={1}>
                    <ImageUpdate
                      required
                      name={'leftSideId'}
                      image={profile?.leftSideId}
                      height={148}
                      width={265}
                      callback={setLeftSideId}
                      className={'upload-image-id'}
                      label={'Mặt A giấy chứng minh thư'}
                    />
                  </Col>
                  <Col span={6} offset={1}>
                    <ImageUpdate
                      required
                      name={'rightSideId'}
                      image={profile?.rightSideId}
                      height={148}
                      width={265}
                      callback={setRightSideId}
                      className={'upload-image-id'}
                      label={'Mặt B giấy chứng minh thư'}
                    />
                  </Col>
                </Row>
              </div>
              <Row>
                <Col span={11}>
                  <ProFormText
                    name={'name'}
                    label={'Họ tên'}
                    placeholder={'Họ tên'}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng điền vào chỗ trống!'
                      }
                    ]}
                  />
                  <ProFormRadio.Group
                    name="gender"
                    label={'Giới tính'}
                    options={[
                      {
                        label: 'Nam',
                        value: 'male'
                      },
                      {
                        label: 'Nữ',
                        value: 'female'
                      }
                    ]}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn giới tính!'
                      }
                    ]}
                  />
                  <ProFormDatePicker
                    name={'birthDate'}
                    label={'Ngày sinh'}
                    placeholder={'Ngày sinh'}
                    fieldProps={{
                      format: 'DD/MM/YYYY'
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng điền vào chỗ trống!'
                      }
                    ]}
                  />
                  <ProFormText
                    name={'numberId'}
                    label={'Số CMND/CCCD'}
                    placeholder={'Số CMND/CCCD'}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng điền vào chỗ trống!'
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: 'Vui lòng chỉ điền số!',
                      }
                    ]}
                  />
                  <ProFormText
                    name={'birthPlace'}
                    label={'Nơi sinh'}
                    placeholder={'Nơi sinh'}
                  />
                  <ProFormText
                    name={'address'}
                    label={'Địa chỉ liên hệ'}
                    placeholder={'Địa chỉ liên hệ'}
                  />
                  <ProFormText
                    name={'zaloInfo'}
                    label={'Thông tin Zalo'}
                    placeholder={'Thông tin Zalo'}
                  />
                  <div>hoặc</div>
                  <ImageUpdate
                    // required
                    name={'zaloQRCode'}
                    image={profile?.zaloQRCode}
                    height={100}
                    width={100}
                    callback={setZaloQRCode}
                    className={'upload-image'}
                  />
                </Col>
                <Col span={12} offset={1}>
                  <ProFormText
                    name={'cellPhone'}
                    label={'Điện thoại di động'}
                    placeholder={'Điện thoại di động'}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng điền vào chỗ trống!'
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: 'Vui lòng chỉ điền số!',
                      }
                    ]}
                  />
                  <ProFormText
                    name={'email'}
                    label={'Địa chỉ Email'}
                    placeholder={'Địa chỉ Email'}
                    rules={[
                      {
                        message: 'Địa chỉ email không đúng!',
                        type: "email",
                      },
                      {
                        required: true,
                        message: 'Vui lòng điền vào chỗ trống!'
                      },
                    ]}
                  />
                  <ProFormText
                    name={'profession'}
                    label={'Nghề nghiệp'}
                    placeholder={'Nghề nghiệp'}
                  />
                  <ProFormText
                    name={'height'}
                    label={'Chiều cao'}
                    placeholder={'Chiều cao'}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng điền vào chỗ trống!',
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: 'Vui lòng chỉ điền số!',
                      }
                    ]}
                    fieldProps={{
                      suffix: ' cm'
                    }}
                  />
                  <ProFormText
                    name={'weight'}
                    label={'Cân nặng'}
                    placeholder={'Cân nặng'}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng điền vào chỗ trống!'
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: 'Vui lòng chỉ điền số!',
                      }
                    ]}
                    fieldProps={{
                      suffix: ' kg'
                    }}
                  />
                  <ProFormText
                    disabled
                    value={bmi}
                    name={'bmi'}
                    label={'Chỉ số BMI'}
                    placeholder={'Chỉ số BMI'}
                    fieldProps={{
                      suffix: ' kg/m2'
                    }}
                  />
                  <ProFormText
                    name={'fbInfo'}
                    label={'Thông tin Facebook'}
                    placeholder={'Thông tin Facebook'}
                  />
                </Col>
              </Row>
              <Row>
                <Space>
                  <ProFormText
                    name={'nameVaccine'}
                    label={'Loại vacxin'}
                    placeholder={'Loại vacxin'}
                  />
                  <ProFormText
                    name={'injectNumber'}
                    label={'Mũi'}
                    placeholder={'Mũi'}
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        message: 'Vui lòng chỉ điền số!',
                      }
                    ]}
                  />
                  <ProFormDatePicker
                    name={'injectDate'}
                    label={'Ngày tiêm'}
                    placeholder={'Ngày tiêm'}
                    fieldProps={{
                      format: 'DD/MM/YYYY'
                    }}
                  />
                </Space>
              </Row>
              <Row><Col style={{ textAlign: 'center' }} span={24}><Space>
                <Button type="primary" loading={loading} danger onClick={() => setIsShow(false)}>{'Hủy'}</Button>
                <Button type="primary" loading={loading} htmlType="submit">{'Cập nhật'}</Button>
              </Space></Col></Row>
            </>
          }}
        </Form>}
      </div>
    </Spin>
  </Modal>);
};

export default UpdateAccountForm;
