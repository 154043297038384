import {lazy} from 'react';

const Routes = [
  {
    name: 'User Management',
    path: '/',
    Component: lazy(() => import('./pages')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.user',
      href: '#'
    }
  }
];

export default Routes;
