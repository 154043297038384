export const requiredTextRule = [
  {
    required: true,
    message: 'Vui lòng điền vào chỗ trống!',
  },
];

export const numberRule = [
  {
    pattern: /^[0-9]*$/,
    message: 'Vui lòng chỉ điền số!',
  }
];

export const requiredNumberRule = [
  {
    required: true,
    message: 'Vui lòng điền vào chỗ trống!',
  },
  {
    pattern: /^[0-9]*$/,
    message: 'Vui lòng chỉ điền số!',
  }
]

export const requiredEmailRule = [
  {
    message: 'Địa chỉ email không đúng!',
    type: "email",
  },
  {
    required: true,
    message: 'Vui lòng điền vào chỗ trống!'
  },
];