import { TOKEN_NAME } from '../constants';
import axiosService from './axiosService';

export const getProjects = async () => {
  try {
    const url = `/api/get/projects`;
    const { data = [] } = await axiosService.get(url);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getAlertsByUserId = async (userId) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME)
    const url = `/api/get/alert/${userId}`;
    const result = await axiosService.get(url, '',
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const getProfileByUserId = async (userId) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME)
    const url = `/api/get/profile/${userId}`;
    const { data = {} } = await axiosService.get(url, '', {
      Authorization: `Bearer ${token}`,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const recoverPassword = async (email) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME)
    const url = `/api/get/recover-password/${email}`;
    const result = await axiosService.get(url, '', {
      Authorization: `Bearer ${token}`,
    });
    return result;
  } catch (e) {
    throw e;
  }
};