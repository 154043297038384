import React, { useContext, useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Col, Divider, Form, Modal, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import * as apis from '../../services/update'
import { AuthContext } from "../../context/authContext/AuthContext";
import '../../index.scss'
import { loginAgain } from "../../context/authContext/AuthActions";
import { ProFormText } from '@ant-design/pro-form';
import { LockOutlined, UserOutlined, HomeOutlined } from '@ant-design/icons';
import { requiredEmailRule } from "../../utils/rules";
import { recoverPassword } from "../../services/get";
import { handleErrors } from "../../utils/handleErrors";
import PasswordUpdate from '../../forms/password-update';
import { useEffect } from 'react';
import { getNewsList } from '../../services/news.service';
import { getAdvertisementList } from '../../services/advertisement.service';
import { getActionImageList } from '../../services/actionImage.service';
import { getLinkURL, sortDescending } from '../../utils';
import imageDefault from '../../images/background.jpg'

const News = () => {
  let formRef = null;
  const history = useHistory();
  const { dispatch, error, isFetching } = useContext(AuthContext);
  const [showRecoverPassword, setShowRecoverPassword] = useState(false);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
  const [data, setData] = useState({})
  const [dataNews, setDataNews] = useState([]);
  const [dataAds, setDataAds] = useState([]);
  const [dataActionImage, setDataActionImage] = useState([]);
  const [hot, setHot] = useState({});
  const query = useParams()
  const params = new URLSearchParams(window.location.search)
  const type = params.get("type") || "news"

  useEffect(async () => {
    const { data } = (type === "news" ? await getNewsList() : await getAdvertisementList())
    setDataNews(data.sort(sortDescending))
  }, [])


  return (<div className={'container-login-new'}>
    <div className='bg_header'></div>
    <Row style={{ margin: "20px", fontSize: "16px", paddingBottom: "10px", borderBottom: "1px black solid" }}>
      <HomeOutlined className='beadcrumb' onClick={() => history.push("/")} style={{ color: "#109CF1", fontSize: "20px", marginRight: "7px" }} /> / {type === "news" ? "Tin tức hoạt động" : "Quảng cáo"}
    </Row>
    <Row>
      {
        dataNews.map(item => <Col onClick={() => history.push(`/news/${item._id}?type=${type}`)} span={8} style={{ padding: "10px", cursor: "pointer" }}>
          <img src={item?.image ? getLinkURL(item?.image, "news") : imageDefault} style={{ width: "100%", height: "200px", objectFit: "cover" }} />
          <div style={{ fontSize: "14px", fontWeight: "bold" }}>{item?.title}</div>
        </Col>)
      }
    </Row>
  </div>);

};

export default News;