import React, { Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch as RouteSwitch } from 'react-router-dom';
import Login from './pages/login';
import './index.scss';
import { AuthContext } from "./context/authContext/AuthContext";
import Routes from './routes';
import Loading from './components/loading';
import Container from './forms/container';
import RegisterAccountPage from "./pages/register-account";
import { getProfileByUserId } from "./services/get";
import { fetchToken, onMessageListener } from './firebase';
import { Button } from 'antd';
import News from './pages/news';
import ActionImages from './pages/actionImages';
import NewDetail from './pages/newsDetail';
import { NotificationViewNew } from './forms/notification-view-new';
require('dotenv/config');

export function App() {
  const { user } = useContext(AuthContext);
  const [hideMenu, setHideMenu] = useState(false);
  const [profile, setProfile] = useState(null);

  const getProfile = async function (userId) {
    const result = await getProfileByUserId(userId);
    setProfile(result);
  }

  useEffect(async () => {
    if (user?._id) {
      await getProfile(user?._id);
    }
  }, [user]);

  const refresh = async () => await getProfile(user?._id);

  const [show, setShow] = useState(false);
  const [payload, setPayload] = useState({})
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);
  console.log('show', show)

  onMessageListener().then(payload => {
    console.log('payload', payload)
    setPayload(payload)
    setShow(true);
  }).catch(err => console.log('failed: ', err));

  return (
    <>
      <Router basename="/">
        <RouteSwitch>
          {user && profile && Routes.map(({ path, Component, exact }) => (
            <Route
              path={path}
              key={path}
              exact={exact}
              render={() => (
                <Suspense fallback={<Loading />}>
                  <Container user={user} setHideMenu={setHideMenu} hideMenu={hideMenu} refresh={refresh}>
                    <Component user={user} hideMenu={hideMenu} profile={profile} refresh={refresh} />
                  </Container>
                </Suspense>
              )}
            />
          ))}
          <Route path="/register-account" exact component={RegisterAccountPage} />
          <Route path="/news" exact component={News} />
          <Route path="/news/:id" exact component={NewDetail} />
          <Route path="/action-images" exact component={ActionImages} />
          <Route path="**">{user && profile ? <Redirect to={'/'} /> : <Login />}</Route>
        </RouteSwitch>
      </Router>
      <NotificationViewNew
        notification={payload?.data || {}}
        isShow={show}
        setIsShow={setShow}
        user={user}
        isFirebase={true}
        payload={payload}
      />
    </>
  );
  // const [show, setShow] = useState(false);
  // const [notification, setNotification] = useState({title: '', body: ''});
  // const [isTokenFound, setTokenFound] = useState(false);
  // fetchToken(setTokenFound);

  // onMessageListener().then(payload => {
  //   setNotification({title: payload.notification.title, body: payload.notification.body})
  //   setShow(true);
  //   console.log(payload);
  // }).catch(err => console.log('failed: ', err));

  // const onShowNotificationClicked = () => {
  //   setNotification({title: "Notification", body: "This is a test notification"})
  //   setShow(true);
  // }

  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       {isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>}
  //       {!isTokenFound && <h1> Need notification permission ❗️ </h1>}
  //       <Button onClick={() => onShowNotificationClicked()}>Show Toast</Button>
  //     </header>

  //   </div>
  // );
}

export default App;
