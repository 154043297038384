import axios from 'axios';
import { TOKEN_NAME } from '../constants';
import { loginFailure, loginStart, loginSuccess } from "../context/authContext/AuthActions";
require('dotenv/config');

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, headers: {
    Authorization: 'Bearer ' + localStorage.getItem(TOKEN_NAME)
  }
});

export const login = async (user, dispatch) => {
  try {
    dispatch(loginStart());
    const res = await axiosInstance.post(`/api/auth/login`, user);
    localStorage.setItem(TOKEN_NAME, res.data?.accessToken)
    if (res.data?.statusCode !== 202)
      dispatch(loginSuccess(res.data));
    return res
  } catch (e) {
    dispatch(loginFailure());
  }
};

export const handleLogout = async () => {
  try {
    await axiosInstance.post(`/api/auth/logout`, { tokenFCM: localStorage.getItem("tokenFCM")|| "eZyggdBz9lpxIaT3lUdjl8:APA91bEbJrjuS3RBzgQNSXqYZJxrYQrHrooH_EwyV7tZjWRwoaKPkHeBd-O7nc1siKYD0BHpuj9K_CaFDF0hl6FCRSbPGK4OfU9pRqVoNQ57CG6WQMThsSgVxD3xXa_d4G6LVIw5I-4P" });
  } catch (e) {
    // dispatch(loginFailure());
  }
};

export const registerAccount = async (payload) => {
  try {
    const url = `/api/create/user`;
    const result = await axiosInstance.post(url, payload, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
    return result;
  } catch (e) {
    throw e;
  }
};

export const updateUserPasswordByUserId = async (userId, payload, token) => {
  try {
    const url = `/api/update/password/${userId}`;
    const result = await axiosInstance.put(url, {
      password: payload.password,
    },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const updateUser = async (userId, payload, token) => {
  try {
    const url = `/api/update/user/${userId}`;
    const result = await axiosInstance.put(url, payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const updateHabit = async (userId, payload, token) => {
  try {
    const url = `/api/update/habit/${userId}`;
    const result = await axiosInstance.put(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return result;
  } catch (e) {
    throw e;
  }
};

export const addProjectMember = async (userId, payload, token) => {
  try {
    const url = `/api/update/project-member/add/${userId}`;
    const result = await axiosInstance.put(url, payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const confirmProjectMember = async (userId, payload, token) => {
  try {
    const url = `/api/update/project-member/confirm-participation/${userId}`;
    const result = await axiosInstance.put(url, payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const removeProjectMember = async (userId, payload, token) => {
  try {
    const url = `/api/update/project-member/remove/${userId}`;
    const result = await axiosInstance.put(url, payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const updateReadById = async (id, token) => {
  try {
    const url = `/api/update/read/${id}`;
    const result = await axiosInstance.put(url, { isRead: true },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};
