import React from 'react';
import 'antd/dist/antd.css';
import '../../index.scss'
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import logo from './../../icons/logo.svg'
import {MenuUser} from "../../components/common/MenuUser";

const Container = ({ user, setHideMenu, hideMenu, refresh, children}) => {
  return (<div className={'container-page'}>
      <div className={'header'}>
        <div className="icon-menu">
          <div onClick={() => setHideMenu(!hideMenu)}>
            {hideMenu ? <MenuUnfoldOutlined title="Mở menu" className="icon"/> :
              <MenuFoldOutlined title="Đóng menu" className="icon"/>}
          </div>
          <div className="logo">
            <img src={logo} height={24}/>
          </div>
        </div>
        <MenuUser user={user} refresh={refresh}/>
      </div>
      <div className={'content-page'}>{children}</div>
    </div>
  );
};

export default Container;
