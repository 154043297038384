import React, { useContext, useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Form, Modal, Space, Spin } from 'antd';
import { ProFormText } from '@ant-design/pro-form';
import { updateUserPasswordByUserId } from "../../services/update";
import { LockOutlined } from '@ant-design/icons';
import { handleErrors } from "../../utils/handleErrors";
import { AuthContext } from "../../context/authContext/AuthContext";
import { logout } from "../../context/authContext/AuthActions";

const PasswordUpdate = ({ user, isShow, setIsShow, isFirst = false }) => {
  const { dispatch } = useContext(AuthContext);
  const logoutUser = () => {
    Modal.info({
      title: 'Thông báo',
      content: (
        <div>
          <p>{'Thay đổi mật khẩu thành công.'}</p>
          <p>{'Vui lòng đăng nhập lại!'}</p>
        </div>
      ),
      onOk() {
        dispatch(logout());
      },
      okText: 'Chấp nhận',
    });
  }
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const result = await updateUserPasswordByUserId(user._id, values, user.accessToken);
      if (result?.status === 204) {
        setLoading(false);
        setIsShow(false);
        logoutUser();
      }
    } catch (e) {
      setHasError(true);
      handleErrors(e, user.logoutUser, setLoading);
    }
  };
  return (<Modal title="Thay đổi mật khẩu" destroyOnClose centered closable={false} visible={isShow} width={340} footer={null}>
    <Spin spinning={loading} tip={'Đang đổi...'}>
      <Form layout={"vertical"} onFinish={onFinish}>
        {(values) => (<>
          {hasError && <p style={{ color: 'red' }}>{'Đã có lỗi xây ra, không thể thay đổi mật khẩu!'}</p>}
          {isFirst && <div style={{ color: "red", fontStyle: "italic", marginBottom: "10px" }}>Tài khoản của bạn đã được đặt lại mật khẩu, vui lòng đổi mật khẩu!</div>}
          <ProFormText.Password
            name="password"
            label={'Mật khẩu mới'}
            fieldProps={{
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Mật khẩu mới'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu!',
              },
            ]}
            onChange={() => setHasError(false)}
          />
          <ProFormText.Password
            name="confirmPassword"
            label={'Xác nhận mật khẩu'}
            fieldProps={{
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Xác nhận mật khẩu'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu!',
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value !== values.password) {
                    callback("Mật khẩu không đúng!");
                  } else {
                    callback();
                  }
                }
              }
            ]}
            onChange={() => setHasError(false)}
          />
          <Space>
            <Button type="primary" loading={loading} danger onClick={() => setIsShow(false)}>{'Hủy'}</Button>
            <Button type="primary" loading={loading} htmlType="submit">{'Gửi'}</Button>
          </Space></>)}
      </Form>
    </Spin>
  </Modal>);
};

export default PasswordUpdate;
