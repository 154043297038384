import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyAtpVJdm9fdNPwE91_T8Izkb5mdBfX3AVE",
  authDomain: "idqc-f2c53.firebaseapp.com",
  projectId: "idqc-f2c53",
  storageBucket: "idqc-f2c53.appspot.com",
  messagingSenderId: "572161424152",
  appId: "1:572161424152:web:385782a6785d42407afc6d",
  measurementId: "G-97X403FB8G"
};

const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const fetchToken = async (setTokenFound) => {
  console.log('messaging', await messaging)
  return getToken(await messaging, { vapidKey: 'BEIKBKs53W1Q5c72MhM9nUvqoT89HVh6gMWxB7DJGAglT2UMYTICx_-sYUZS5r2ttsDsoDRNVh00NVINlF2jvDg' }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      localStorage.setItem("tokenFCM", currentToken || "eZyggdBz9lpxIaT3lUdjl8:APA91bEbJrjuS3RBzgQNSXqYZJxrYQrHrooH_EwyV7tZjWRwoaKPkHeBd-O7nc1siKYD0BHpuj9K_CaFDF0hl6FCRSbPGK4OfU9pRqVoNQ57CG6WQMThsSgVxD3xXa_d4G6LVIw5I-4P")
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      onMessage(messagingResolve, (payload) => {
        // console.log('On message: ', messaging, payload);
        resolve(payload);
      });
    })()
  );