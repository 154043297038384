import React from "react";
import {message} from "antd";

export const handleErrors = function(error, logout, setLoading, customMessage) {
  if (error?.response?.status === 422) {
    message.error(error?.response?.data, 10);
  } else if (error?.response?.status === 401) {
    logout && logout();
    localStorage.removeItem("user")
  } else {
    console.log(error);
    message.error(customMessage || 'Đã có lỗi xẩy ra, vui lòng đăng nhập lại!', 10);
  }
  setLoading && setLoading(false);
};